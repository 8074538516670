<template>
	<Head :title="__('Log in')" />

	<AuthenticationCard>
		<div class="card card--main">
			<header class="card__header">
				<h1>
					{{ __('Log in') }}
				</h1>
			</header>

			<p v-if="status" class="alert alert--success">
				{{ status }}
			</p>

			<form class="form" @submit.prevent="submit">
				<div class="form__item" :class="{ 'has-error': form.errors.email }">
					<InputLabel for="email" value="Email" />

					<TextInput
						id="email"
						v-model="form.email"
						type="email"
						required
						autofocus
						autocomplete="username" />

					<InputError :message="form.errors.email" />
				</div>

				<div class="form__item" :class="{ 'has-error': form.errors.password }">
					<InputLabel for="password" value="Password" />

					<TextInput
						id="password"
						v-model="form.password"
						type="password"
						required
						autocomplete="current-password" />

					<InputError :message="form.errors.password" />
				</div>

				<div class="form__item has-checkbox">
					<Checkbox
						id="remember"
						v-model:checked="form.remember"
						name="remember" />

					<label for="remember">
						{{ __('Remember me') }}
					</label>
				</div>

				<div class="form__item flex align-center justify-end gap-md">
					<Link v-if="canResetPassword" :href="route('password.request')">
						{{ __('Forgot your password?') }}
					</Link>

					<PrimaryButton
						:class="{ 'opacity-25': form.processing }"
						:disabled="form.processing">
						{{ __('Log in') }}
					</PrimaryButton>
				</div>
			</form>
		</div>
	</AuthenticationCard>
</template>

<script setup>
import { Head, Link, useForm } from '@inertiajs/vue3';
import AuthenticationCard from '@/Components/AuthenticationCard.vue';
import Checkbox from '@/Components/Checkbox.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';

defineProps({
	canResetPassword: {
		type: Boolean,
		required: true,
	},
	status: {
		type: String,
		default: () => '',
	},
});

const form = useForm({
	email: '',
	password: '',
	remember: false,
});

const submit = () => {
	form.transform(data => ({
		...data,
		remember: form.remember ? 'on' : '',
	})).post(route('login'), {
		onFinish: () => form.reset('password'),
	});
};
</script>
